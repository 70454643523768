html, body {
  background-color: #fff;
  scroll-behavior: smooth;
}

.covid-pass {
  line-height: 1.4;
}

.covid-pass main {
  font-family: system-ui, sans-serif;
  color: #000000;
  -webkit-font-smoothing: antialiased;
}

.covid-pass main > * {
  margin-top: 32px;
  margin-bottom: 32px;
}

@media only screen and (min-width: 600px) {
  .covid-pass main > *:not(:first-child) {
    margin-bottom: 64px;
  }
}

.covid-pass h1 {
  font-weight: 800;
}

.covid-pass h2 {
  font-weight: 700;
}

.covid-pass h3 {
  font-weight: 700;
}

.covid-pass h4 {
  font-weight: 700;
}

.covid-pass a {
  color: inherit;
}

.covid-pass em, .covid-pass i {
  font-style: italic;
}

@media only screen and (min-width: 0px) {
  .covid-pass {
    font-size: 17px;
  }
  .covid-pass h1 {
    font-size: 34px;
  }
  .covid-pass h2 {
    font-size: 26px;
  }
  .covid-pass h3 {
    font-size: 20px;
  }
  .covid-pass h4 {
    font-size: 17px;
  }
  .covid-pass small {
    font-size: 14px;
  }
}

@media only screen and (min-width: 600px) {
  .covid-pass {
    font-size: 20.4px;
  }
  .covid-pass h1 {
    font-size: 40.8px;
  }
  .covid-pass h2 {
    font-size: 31.2px;
  }
  .covid-pass h3 {
    font-size: 24px;
  }
  .covid-pass h4 {
    font-size: 20.4px;
  }
  .covid-pass small {
    font-size: 16.8px;
  }
}

@media only screen and (min-width: 800px) {
  .covid-pass {
    font-size: 25.5px;
  }
  .covid-pass h1 {
    font-size: 51px;
  }
  .covid-pass h2 {
    font-size: 39px;
  }
  .covid-pass h3 {
    font-size: 30px;
  }
  .covid-pass h4 {
    font-size: 25.5px;
  }
  .covid-pass small {
    font-size: 21px;
  }
}

.covid-pass .content {
  max-width: 800px;
  margin: 0 auto;
}

.covid-pass .content * + * {
  margin-top: 20px;
}

@media only screen and (min-width: 0px) {
  .covid-pass .content {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media only screen and (min-width: 600px) {
  .covid-pass .content {
    padding-left: 96px;
    padding-right: 96px;
  }
}

@media only screen and (min-width: 800px) {
  .covid-pass .content {
    padding-left: 128px;
    padding-right: 128px;
  }
}

.covid-pass .hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.covid-pass main > header {
  color: #828282;
}

.covid-pass main > header nav {
  display: table;
}

.covid-pass main > header nav > * {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (max-width: 350px) {
  .covid-pass main > header nav > * {
    display: block;
  }
}

.covid-pass main > header .branding {
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
}

.covid-pass main > header .branding a {
  text-decoration: none;
}

.covid-pass main > header .branding a:hover {
  color: #000000;
}

.covid-pass main > header .anchors {
  width: 100%;
  text-align: right;
}

.covid-pass main > header .anchors a {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  margin-top: 0;
}

.covid-pass main > header .anchors a:hover {
  text-decoration: underline;
}

.covid-pass main > header .anchors a:not(:last-child) {
  margin-right: 1em;
}

.covid-pass main > header .anchors a figure {
  display: inline;
  margin-right: 2px;
}

.covid-pass main > header .anchors a figure svg {
  vertical-align: middle;
}

.covid-pass .introduction .hero {
  padding: 20px;
}

.covid-pass .introduction .hero img {
  display: block;
  max-height: 600px;
  margin: 0 auto;
}

.covid-pass .introduction img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.covid-pass .introduction h1 {
  font-weight: 800;
  line-height: 1.2;
  color: #000000;
}

.covid-pass .introduction h2 {
  font-weight: 600;
  line-height: 1.3;
  color: #828282;
}

.covid-pass .introduction .emoji {
  background-image: url(Emojis.6283fbf3.jpg);
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  margin: 0px;
  margin-bottom: -0.15em;
}

@media only screen and (min-width: 0px) {
  .covid-pass .introduction h1 {
    font-size: 34px;
  }
  .covid-pass .introduction h2 {
    font-size: 29px;
  }
  .covid-pass .introduction .emoji {
    background-size: 123px 28px;
  }
  .covid-pass .introduction .emoji.frog {
    width: 29px;
    height: 23px;
    background-position: 0px 0px;
  }
  .covid-pass .introduction .emoji.peach {
    width: 28px;
    height: 28px;
    background-position: -29px 0px;
  }
  .covid-pass .introduction .emoji.apple-watch {
    width: 20px;
    height: 29px;
    background-position: -57px 0px;
  }
  .covid-pass .introduction .emoji.iphone {
    width: 16px;
    height: 29px;
    background-position: -78px 0px;
  }
  .covid-pass .introduction .emoji.wallet {
    width: 29px;
    height: 29px;
    background-position: -94px 0px;
  }
}

@media only screen and (min-width: 400px) {
  .covid-pass .introduction h1 {
    font-size: 42.5px;
  }
  .covid-pass .introduction h2 {
    font-size: 36.25px;
  }
  .covid-pass .introduction .emoji {
    background-size: 153.75px 35px;
  }
  .covid-pass .introduction .emoji.frog {
    width: 36.25px;
    height: 28.75px;
    background-position: 0px 0px;
  }
  .covid-pass .introduction .emoji.peach {
    width: 35px;
    height: 35px;
    background-position: -36.25px 0px;
  }
  .covid-pass .introduction .emoji.apple-watch {
    width: 25px;
    height: 36.25px;
    background-position: -71.25px 0px;
  }
  .covid-pass .introduction .emoji.iphone {
    width: 20px;
    height: 36.25px;
    background-position: -97.5px 0px;
  }
  .covid-pass .introduction .emoji.wallet {
    width: 36.25px;
    height: 36.25px;
    background-position: -117.5px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .covid-pass .introduction h1 {
    font-size: 51px;
  }
  .covid-pass .introduction h2 {
    font-size: 43.5px;
  }
  .covid-pass .introduction .emoji {
    background-size: 184.5px 42px;
  }
  .covid-pass .introduction .emoji.frog {
    width: 43.5px;
    height: 34.5px;
    background-position: 0px 0px;
  }
  .covid-pass .introduction .emoji.peach {
    width: 42px;
    height: 42px;
    background-position: -43.5px 0px;
  }
  .covid-pass .introduction .emoji.apple-watch {
    width: 30px;
    height: 43.5px;
    background-position: -85.5px 0px;
  }
  .covid-pass .introduction .emoji.iphone {
    width: 24px;
    height: 43.5px;
    background-position: -117px 0px;
  }
  .covid-pass .introduction .emoji.wallet {
    width: 43.5px;
    height: 43.5px;
    background-position: -141px 0px;
  }
}

@media only screen and (min-width: 800px) {
  .covid-pass .introduction h1 {
    font-size: 68px;
  }
  .covid-pass .introduction h2 {
    font-size: 58px;
  }
  .covid-pass .introduction .emoji {
    background-size: 246px 56px;
  }
  .covid-pass .introduction .emoji.frog {
    width: 58px;
    height: 46px;
    background-position: 0px 0px;
  }
  .covid-pass .introduction .emoji.peach {
    width: 56px;
    height: 56px;
    background-position: -58px 0px;
  }
  .covid-pass .introduction .emoji.apple-watch {
    width: 40px;
    height: 58px;
    background-position: -114px 0px;
  }
  .covid-pass .introduction .emoji.iphone {
    width: 32px;
    height: 58px;
    background-position: -156px 0px;
  }
  .covid-pass .introduction .emoji.wallet {
    width: 58px;
    height: 58px;
    background-position: -188px 0px;
  }
}

.covid-pass main > header hgroup {
  line-height: 1.2;
}

.covid-pass main > header h2 {
  color: #828282;
}

.covid-pass .generator {
  text-align: center;
}

.covid-pass .generator .grouped-container {
  background: #F9F9FB;
}

.covid-pass .generator .grouped-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0;
}

@media only screen and (max-width: 370px) {
  .covid-pass .generator {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.covid-pass .generator .widget .add-to-wallet {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity ease-out 0.2s;
}

.covid-pass .generator .widget .add-to-wallet[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.covid-pass .generator .widget .label {
  display: block;
  margin-top: 0;
  font-size: 22px;
  font-weight: 600;
}

.covid-pass .generator .widget .icon {
  margin-bottom: 8px;
}

.covid-pass .generator aside {
  font-size: 17px;
  color: #828282;
}

.covid-pass .generator aside a {
  color: inherit;
  text-decoration: underline;
}

.covid-pass .where-did-it-go .open-wallet li {
  display: table;
  width: 100%;
}

.covid-pass .where-did-it-go .open-wallet li span {
  display: table-cell;
  vertical-align: top;
  padding-left: 16px;
}

.covid-pass .where-did-it-go .open-wallet li .icon {
  padding-top: 4px;
}

.covid-pass .where-did-it-go .device {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 40px;
  display: block;
  height: 330px;
  background-size: cover;
  background-position: top center;
  background-image: url(Screenshot@2x.b78c92d4.png);
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 0px) {
  .covid-pass .where-did-it-go .device {
    height: 400px;
  }
}

@media only screen and (min-width: 600px) {
  .covid-pass .where-did-it-go .device {
    height: 500px;
  }
}

.covid-pass .qa header {
  text-align: center;
}

.covid-pass .qa header figure {
  display: block;
  margin: 0 auto;
}

.covid-pass .qa .questions > li + li {
  margin-top: 2em;
}

.covid-pass .qa .tagline {
  display: block;
  color: #828282;
}

.covid-pass main > footer {
  font-size: 14px;
  color: #828282;
  margin-top: 40px;
  margin-bottom: 100px;
}

.covid-pass main > footer a {
  font-weight: 600;
  text-decoration: none;
}

.covid-pass main > footer .content * + * {
  margin-top: 4px;
}
/*# sourceMappingURL=index.41426c41.css.map */
